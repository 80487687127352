import axios from 'axios'

/* Desenvolvimento */
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

axios.defaults.baseURL = 'https://api-almox.cardosomoreira.rj.gov.br/api/';

axios.defaults.headers.common['Authorization'] = '' + sessionStorage.getItem("token");

export const axiosConfig = () => {
  axios.interceptors.response.use((config) => config, async (error) => {
    if (error.response.status === 401 ) {
      sessionStorage.removeItem('token');
      location.href='/autenticacao/login';
    }
    return Promise.reject(error);
  })
};
