import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import DashboardLayout from "@/layout/dashboard.vue";
import { useCriptoStore } from "@/store/store";

const checkAuth = async function (to:any) {
  try {
    if (!window.sessionStorage.getItem('token')) {
      return false;
    }
    const criptoStore = useCriptoStore();
    var permissoes = await criptoStore.decryptBack(sessionStorage.getItem("permissao"));
    let verificacao=true;
    permissoes = JSON.parse(permissoes);

    if('hab_'+to.meta.modulo in permissoes){
      verificacao = permissoes['hab_'+to.meta.modulo];
    }

    return verificacao;
  } catch (error) {
      console.log(error)
  }
  
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "area-de-trabalho" },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },

  {
    name: "administracao",
    path: "/painel",
    component: DashboardLayout,
    beforeEnter: async (to, from, next) => {
      try {
        return next();
      } catch (e) {
        next("/autenticacao/login");
      }
    },
    children: [
      {
        name: "area-de-trabalho",
        path: "pagina-inicial",
        props: true,
        component: () => import("@/views/admin/painel/index.vue"),
        meta: {
          title: 'Area de Trabalho',
          modulo: 'area_de_trabalho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "processar-pedido",
        path: "pedidos-pendentes",
        props: true,
        component: () => import("@/views/admin/pedidos/pedidos.vue"),
        meta: {
          title: 'Processar Pedidos',
          modulo: 'pedido'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "acompanhar-pedido",
        path: "pedidos/acompanhar",
        props: true,
        component: () =>
          import("@/views/admin/pedidos/acompanharPedido.vue"),
        meta: {
          title: 'Acompanhar Pedidos',
          modulo: 'gerar_pedido'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "liberar-pedido",
        path: "pedidos/liberar",
        props: true,
        component: () =>
          import("@/views/admin/pedidos/liberarPedido.vue"),
        meta: {
          title: 'Liberar Pedidos',
          modulo: 'liberar_pedido'
        },
        beforeEnter: async (to, from, next) => {
          try {
            let x = await checkAuth(to);
            console.log("SDDDE", x);
            if(true){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },// acerto do estoque
      {           
        name: "aprovar-acerto-estoque",
        path: "aprovar/acerto/estoque",
        props: true,
        component: () =>
          import("@/views/admin/acerto_estoque/acerto_estoque_aprovar.vue"),
        meta: {
          title: 'Acompanhar Pedidos',
          modulo: 'hab_aprovacao_acerto_estoque'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "gerenciar-pedido",
        path: "pedidos/novo",
        props: true,
        component: () =>
          import("@/views/admin/pedidos/gerenciarPedido.vue"),
        meta: {
          title: 'Gerenciar Pedidos',
          modulo: 'gerar_pedido'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "gerenciar-pedido-fornecedor",
        path: "pedidos-ao-fornecedor/novo",
        props: true,
        component: () =>
          import("@/views/admin/pedidos/gerenciarPedidoFornecedor.vue"),
        meta: {
          title: 'Gerenciar Pedidos ao Fornecedor',
          modulo: 'empenho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "acompanhar-pedido-fornecedor",
        path: "pedidos-ao-fornecedor/listar",
        props: true,
        component: () =>
          import("@/views/admin/pedidos/acompanharPedidoFornecedor.vue"),
        meta: {
          title: 'Acompanhar Pedidos',
          modulo: 'empenho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      /*{
        name: "escolas-lista",
        path: "escolas/visualizar",
        props: true,
        component: () =>
          import("@/views/admin/escola/escolas-lista/escolas.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            return next();
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },*/

      // Nutricionista
      {
        name: "gerenciar-cardapio",
        path: "cardapios/visualizar",
        props: true,
        component: () =>
          import("@/views/admin/nutricionista/cardapios/cardapio.vue"),
        meta: {
          title: 'Gerenciar Cardapios',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Lista de produtos estoque
      {
        name: "lista-de-produtos-estoque",
        path: "produtos-estoque/listar",
        props: true,
        component: () => import("@/views/admin/produtos-estoque/produtosALL.vue"),
        meta: {
          title: 'Listar Produtos',
          modulo: 'produto'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },



      // Lista de produtos nutricionista
      {
        name: "lista-de-produtos",
        path: "produtos/listar",
        props: true,
        component: () => import("@/views/admin/produtos/produtosALL.vue"),
        meta: {
          title: 'Listar Produtos',
          modulo: 'produto'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Cadastrar de produto
      {
        name: "cadastrar-produto",
        path: "produtos/cadastrar",
        props: true,
        component: () => import("@/views/admin/produtos/cadastrarProduto.vue"),
        meta: {
          title: 'Cadastrar Produtos',
          modulo: 'produto'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar de produto
      {
        name: "editar-produto",
        path: "produtos/editar",
        props: true,
        component: () => import("@/views/admin/produtos/editarProduto.vue"),
        meta: {
          title: 'Editar Produtos',
          modulo: 'produto'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      //CARDAPIO

      // Cadastrar cadarpio
      {
        name: "cadastrar-cardapio",
        path: "cardapios/cadastrar",
        props: true,
        component: () => import("@/views/admin/cardapio/cadastrar.vue"),
        meta: {
          title: 'Cadastrar Cardapios',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar cardapio
      {
        name: "editar-cardapio",
        path: "cardapios/editar",
        props: true,
        component: () => import("@/views/admin/cardapio/editar.vue"),
        meta: {
          title: 'Editar Cardapio',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
       // Listar cardapio visualizar
       {
        name: "listar-cardapios-visualizar",
        path: "cardapios/listar/visualizar",
        props: true,
        component: () => import("@/views/admin/cardapio/listaVisualizar.vue"),
        meta: {
          title: 'Listar Cardapio',
          modulo: 'visualizar_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar cardapio
      {
        name: "listar-cardapios",
        path: "cardapios/listar",
        props: true,
        component: () => import("@/views/admin/cardapio/listar.vue"),
        meta: {
          title: 'Listar Cardapio',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Listar cardapio enviado para escola
      {
        name: "listar-cardapios-enviados",
        path: "cardapio/escola/listar",
        props: true,            
        component: () => import("@/views/admin/cardapio-enviado-escola/lista.vue"),
        meta: {
          title: 'Listar Cardápios Enviados',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      //Itens do cardapio

      // Cadastrar/editar item cadarpio
      {
        name: "listar-itens-cardapio",
        path: "itens-cardapio/listar",
        props: true,
        component: () => import("@/views/admin/cardapio/itens-cardapio/item-cardapio.vue"),
        meta: {
          title: 'Listar Itens Cadarpio',
          modulo: 'pedido'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Permissoes visualizar
      {
        name: "listar-permissao",
        path: "permissao/listar",
        props: true,
        component: () => import("@/views/admin/permissao/permissao.vue"),
        meta: {
          title: 'Listar Permissao',
          modulo: 'permissao'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
    // Permissoes Editar
      // {
      //   name: 'editar-permissao',
      //   path: 'permissao/editar',
      //   props:true,
      //   component: () => import('@/views/admin/permissao/editarPermissao.vue'), 
      //   meta: {
      //     title: 'Editar Permissao',
      //     modulo: 'permissao'
      //   },
      //   beforeEnter: async (to, from, next) => {
      //     try {
      //       return next(); 
      //     }catch(e){
      //       next('/autenticacao/login');
      //     }
      //   }
      // },
      // Permissoes adicionar
      {
        name: "adicionar-permissao",
        path: "permissao/cadastrar",
        props: true,
        component: () => import("@/views/admin/permissao/addPermissao.vue"),
        meta: {
          title: 'Adicionar Permissao',
          modulo: 'permissao'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Permissoes Editar
      {
        name: "editar-permissao",
        path: "permissao/editar/:id",
        props: true,
        component: () => import("@/views/admin/permissao/editarPermissao.vue"),
        meta: {
          title: 'Permissao Editar',
          modulo: 'permissao'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      {
        name: "listar-estoque",
        path: "estoque/listar",
        props: true,
        component: () => import("@/views/admin/estoque/estoque.vue"),
        meta: {
          title: 'Listar Estoque',
          modulo: 'alteracao_estoque'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      {
        name: "listar-movimentacao-estoque",
        path: "estoque/movimentacao/listar",
        props: true,
        component: () => import("@/views/admin/estoque/movimentacao_estoque.vue"),
        meta: {
          title: 'Listar Movimentação Estoque',
          modulo: 'alteracao_estoque'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      {
        name: "transferir-produto-unidades",
        path: "estoque/transferir-produtos-entre-unidades",
        props: true,
        component: () => import("@/views/admin/estoque/transferencias-unidade.vue"),
        meta: {
          title: 'Transferir Produtos Estoque',
          modulo: 'liberar_pedido'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      {
        name: "insercao-estoque",
        path: "estoque/inserir",
        props: true,
        component: () => import("@/views/admin/estoque/InsercaoItens.vue"),
        meta: {
          title: 'Inserir Estoque',
          modulo: 'produto'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      {
        name: "acerto-estoque",
        path: "estoque/gerenciar/:id",
        props: true,
        component: () => import("@/views/admin/acerto_estoque/acerto_estoque.vue"),
        meta: {
          title: 'Acerto de Estoque',
          modulo: 'estoque'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Usuarios
      // Cadastrar usuario
      {
        name: "cadastrar-usuario",
        path: "usuario/cadastrar",
        props: true,
        component: () => import("@/views/admin/usuarios/cadastrar.vue"),
        meta: {
          title: 'Cadastrar',
          modulo: 'usuario'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar usuario
      {
        name: "editar-usuario",
        path: "usuario/editar/:id",
        props: true,
        component: () => import("@/views/admin/usuarios/editar.vue"),
        meta: {
          title: 'Editar Usuario',
          modulo: 'usuario'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar usuario
      {
        name: "meus-dados-usuario",
        path: "usuario/meus-dados",
        props: true,
        component: () => import("@/views/admin/usuarios/editar-meus-dados.vue"),
        meta: {
          title: 'Area de Trabalho',
          modulo: 'area_de_trabalho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      //Logos Dinamicos
      {
        name: "logo-dashboard",
        path: "logo-dashboard",
        props: true,
        component: () => import("@/views/admin/logo-dinamico/logoALL.vue"),
        meta: {
          title: 'Logo Dashboard',
          modulo: 'logo-dinamico'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Lista de fornecedores
      {
        name: "listar-fornecedores",
        path: "fornecedores/listar",
        props: true,
        component: () => import("@/views/admin/fornecedor/listar.vue"),
        meta: {
          title: 'Listar Fornecedores',
          modulo: 'empenho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Lista de categorias
      {
        name: "listar-categorias",
        path: "categorias-de-produto/listar",
        props: true,
        component: () => import("@/views/admin/categoria_produto/listar.vue"),
        meta: {
          title: 'Listar Categoria',
          modulo: 'produto'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Lista de pratos
      {
        name: "listar-pratos",
        path: "pratos/listar",
        props: true,
        component: () => import("@/views/admin/prato/listar.vue"),
        meta: {
          title: 'Listar Pratos',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Cadastrar prato
      {
        name: "cadastrar-prato",
        path: "prato/cadastrar",
        props: true,
        component: () => import("@/views/admin/prato/cadastrar.vue"),
        meta: {
          title: 'Cadastrar Prato',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar empenho
      {
        name: "editar-prato",
        path: "prato/editar/:modo/:id",
        props: true,
        component: () => import("@/views/admin/prato/editar.vue"),
        meta: {
          title: 'Editar prato',
          modulo: 'monta_cardapio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Lista de empenhos
      {
        name: "listar-empenhos",
        path: "empenhos/listar",
        props: true,
        component: () => import("@/views/admin/empenho/listar.vue"),
        meta: {
          title: 'Listar Empenho',
          modulo: 'empenho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Cadastrar empenho
      {
        name: "cadastrar-empenho",
        path: "empenho/cadastrar",
        props: true,
        component: () => import("@/views/admin/empenho/cadastrar.vue"),
        meta: {
          title: 'Cadastrar Empenho',
          modulo: 'empenho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar empenho
      {
        name: "editar-empenho",
        path: "empenho/editar/:modo/:id",
        props: true,
        component: () => import("@/views/admin/empenho/editar.vue"),
        meta: {
          title: 'Editar Empenho',
          modulo: 'empenho'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar de usuario
      {
        name: "listar-usuarios",
        path: "usuarios/listar",
        props: true,
        component: () => import("@/views/admin/usuarios/listar.vue"),
        meta: {
          title: 'Listar Usuario',
          modulo: 'usuario'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Unidade
      // Cadastrar unidade
      {
        name: "cadastrar-unidade",
        path: "unidade/cadastrar",
        props: true,
        component: () => import("@/views/admin/unidades/cadastrar.vue"),
        meta: {
          title: 'Listar Unidade',
          modulo: 'gerencia_unidade'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar unidade
      {
        name: "editar-unidade",
        path: "unidade/editar/:id",
        props: true,
        component: () => import("@/views/admin/unidades/editar.vue"),
        meta: {
          title: 'Editar Unidade',
          modulo: 'gerencia_unidade'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Listar de unidades
      {
        name: "listar-unidades",
        path: "unidades/listar",
        props: true,
        component: () => import("@/views/admin/unidades/listar.vue"),
        meta: {
          title: 'Listar Unidades',
          modulo: 'gerencia_unidade'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },



      // Valores da per capita
      {
        name: "per-capita-cardapio",
        path: "per-capita-cardapio/listar",
        props: true,
        component: () => import("@/views/admin/per_capita/listar.vue"),
        meta: {
          title: 'Per Capita Cárdapio',
          modulo: 'monta_carpadio'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
    ],
   
  },
 
  

  /*
  //ambiente Escola
  {
    name: 'ambiente-escola',
    path: '/escola',
    component: DashboardLayout,
    beforeEnter: async (to, from, next) => {
      try {
        return next();

      }catch(e){
        next('/autenticacao/login');
      }
    },
    redirect: '/painel',
    children: [
      {
        name: 'painel-escola',
        path: 'painel',
        props:true,
        component: () => import('@/views/admin/escola/painel/index.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            return next(); 
          }catch(e){
            next('/autenticacao/login');
          }
        },
      },
      {
        name: 'escola-pedido',
        path: 'pedido',
        props:true,
        component: () => import('@/views/admin/pedidos/pedidos.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            return next(); 
          }catch(e){
            next('/autenticacao/login');
          }
        },
      },
      {
        name: 'pedidoescola',
        path: 'pedidoescola',
        props:true,
        component: () => import('@/views/admin/escola/pedidos/pedido.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            return next(); 
          }catch(e){
            next('/autenticacao/login');
          }
        },
      },
      {
        name: 'realizar-pedido',
        path: 'realizar-pedido',
        props:true,
        component: () => import('@/views/admin/escola/pedidos/realizar-pedido.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            return next(); 
          }catch(e){
            next('/autenticacao/login');
          }
        },
      },
      {
        name: 'escolas-lista',
        path: 'escolas-lista',
        props:true,
        component: () => import('@/views/admin/escola/escolas-lista/escolas.vue'), 
        beforeEnter: async (to, from, next) => {
          try {
            return next(); 
          }catch(e){
            next('/autenticacao/login');
          }
        },
      },


      // Nutricionista
      {
        name: 'cardapio',
        path: 'cardapio',
        props:true,
        component: () => import('@/views/admin/nutricionista/cardapios/cardapio.vue'), 
        beforeEnter: async (to, from, next) => {
          try {
            return next(); 
          }catch(e){
            next('/autenticacao/login');
          }
        },
      },
      
    ]  
  },*/
  {
    name: "login",
    path: "/autenticacao/login",
    component: () => import("@/views/auth/login.vue"),
    meta: {
      titulo: "Autenticação",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
