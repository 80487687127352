<template>
  <div id="hideShowSideBar" class="sidebar" :style="{ width: sidebarWidth }">
    <div v-if="reload_logo == 1">
      <span
        class="collapse-icon"
        :class="{ 'rotate-180': collapsed }"
        @click="closeSideBar"
      >
        <i class="pi pi-times"></i>
      </span>
      <h2 class="cursorShow" v-if="logo == null" @click="redirect">
        <img
          style="margin-left: 15px; height: 30px; width:85%;"
          v-if="collapsed"
          src="@/assets/logo.png"
          alt="logo"
        />
        <img
          style="margin-left: 10px; height: 57px;margin-bottom: 18px; width:85%;"
          v-else
          src="@/assets/logo.png"
          alt="logo"
        />
      </h2>
      <h2 class="cursorShow" v-else  @click="redirect">
        <img
          style="margin-left: 15px; height: 30px;width:85%;"
          v-if="collapsed"
          :src="url+'storage/'+logo"
          alt="logo"
        />
        <img
          style="margin-left: 10px; height: 57px;margin-bottom: 18px; width:85%;"
          v-else
          :src="url+'storage/'+logo"
          alt="logo"
        />
      </h2>
    </div>
    <div>
      <DropdownComponent
        v-for="(item, index) in menuTree"
        :key="index"
        :data="item.children"
        :label="item.label"
        :icon="item.icon"
        :depth="0"
        :smallMenu="false"
        :route="item.route"
        @click="fecharSideBarMobile(item.label)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { collapsed, toggleSidebar, sidebarWidth } from "./state";

import DropdownComponent from "./DropdownComponent.vue";

import { useCriptoStore } from "@/store/store";

import axios from "axios"; 

export default defineComponent({
  name: "SidebarComponent",
  props: {},
  components: {
    DropdownComponent,
  },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },
  data() {
    return {
      logo:null,
      menuTree: [],
      url: axios.defaults.baseURL.indexOf('.br/api')!=-1 ? axios.defaults.baseURL.replace('br/api','br') : axios.defaults.baseURL.replace('/api',''),
      reload_logo:0,
    };
  },
  computed: {},
  methods: {
    redirect() {
      this.$router.push({ path: '/painel/pagina-inicial' })
    },
    fecharSideBarMobile(item) {
      if (item == "Configurações") return false;
      if (item == "Controle") return false;
      if (item == "Pedidos") return false;

      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width <= 800) {
        this.closeSideBar();
      }
    },
    ajustarSize(size) {
      this.$emit("ajustarSize", size);
    },
    closeSideBar() {
      document.getElementById("hideShowSideBar").style.display = "none";
      this.ajustarSize("0px");
    },
    openPosition(position) {
      this.position = position;
      this.displayPosition = true;
    },
    closePosition() {
      this.displayPosition = false;
    },
  },
  async beforeMount() {
    let dt = await axios.get('configuracao-logo/'+1);
    this.logo  = dt.data.logo;
    this.reload_logo = 1;

    var main = [
      { label: "Dashboard", icon: "fas fa-columns", route: "area-de-trabalho" },
      /*{ label: "Escolas", icon: "fas fa-chalkboard-user", oute: "escolas-lista", }*/
    ];

    var base = {};
    const criptoStore = useCriptoStore();
    var permissoes = await criptoStore.decryptBack(
      sessionStorage.getItem("permissao")
    );
    var p = JSON.parse(permissoes);
    if (p.hab_alteracao_estoque) {
      main.push({
        label: "Estoque",
        route: "listar-estoque",
        icon: "fas fa-scroll",
      });
    }
    if (p.hab_visualizar_cardapio) {
      main.push({
        label: "Cardápio(s) da escola",
        route: "listar-cardapios-visualizar",
        icon: "fa-solid fa-cubes-stacked",
      });
    }
    if (p.hab_empenho) {
      main.push({
        label: "Empenhos",
        route: "listar-empenhos",
        icon: "fas fa-newspaper",
      });
    }
    if (p.hab_monta_cardapio) {
      main.push({
        label: "Cardápio",
        route: "listar-cardapios",
        icon: "fas fa-scroll",
      });
      main.push({
        label: "Cardápio(s) enviado(s) para escola",
        route: "listar-cardapios-enviados",
        icon: "fa-solid fa-share",
      });
    }
    if (p.hab_monta_cardapio) {
      main.push({
        label: "Prato",
        route: "listar-pratos",
        icon: "fas fa-scroll",
      });
    }
    if (p.hab_monta_cardapio) {
      main.push({
        label: "Lista de Produtos",
        icon: "fas fa-utensils",
        route: "lista-de-produtos",
      });

      main.push({
        label: "Per Capita Cardápio",
        icon: "fa-solid fa-percent",
        route: "per-capita-cardapio",
      });
    }
    

    if (p.hab_gerar_pedido) {
      base = { label: "Pedidos", icon: "fas fa-paste", children: [] };
      
      if (!p.hab_empenho) {
        base.children.push({
          label: "Fazer Pedido",
          route: "gerenciar-pedido",
        });
        base.children.push({
          label: "Acompanhar Pedidos",
          route: "acompanhar-pedido",
        });
      }
    
      if (p.hab_liberar_pedido) {
        base.children.push({
          label: "Liberar Pedidos para as Escolas",
          route: "liberar-pedido",
        });
      }

      if (p.hab_empenho) {
        base.children.push({
          label: "Fazer Pedido ao Fornecedor",
          route: "gerenciar-pedido-fornecedor",
        });
        base.children.push({
          label: "Acompanhar Pedidos ao Fornecedor",
          route: "acompanhar-pedido-fornecedor",
        });
      }

      main.push(base);
    }
    if (
      p.hab_liberar_pedido ||
      p.hab_alteracao_estoque ||
      p.hab_aprovacao_acerto_estoque ||
      p.hab_gerencia_unidade ||
      p.hab_empenho
    ) {
      base = { label: "Controle", icon: "fas fa-gears", children: [] };
      if (p.hab_liberar_pedido) {
        base.children.push({
          label: "Transferencia de Produtos",
          route: "transferir-produto-unidades",
        });
      }
      if (p.hab_aprovacao_acerto_estoque) {
        base.children.push({
          label: "Aprovar acerto de Estoque",
          route: "aprovar-acerto-estoque",
        });
      }

      if (p.hab_acerto_estoque || p.hab_alteracao_estoque) {
        base.children.push({
          label: "Movimentação do Estoque",
          route: "listar-movimentacao-estoque",
        });
      }
       
    
      if (p.hab_empenho) {
        base.children.push({
          label: "Lista de Fornecedores",
          route: "listar-fornecedores",
        });
      }
      if (p.hab_liberar_pedido && p.hab_produto) {
        base.children.push({
          label: "Categorias de Produto",
          route: "listar-categorias",
        });
      }
      if (p.hab_liberar_pedido && p.hab_produto) {
        if (p.hab_empenho) {
          base.children.push({
            label: "Lista de Produtos",
            route: "lista-de-produtos-estoque",
          });
        }
      }
      if (base.children.length)
        main.push(base);
    }
    if (p.hab_permissao || p.hab_usuario) {
      base = { label: "Configurações", icon: "fas fa-gear", children: [] };
      if (p.hab_permissao) {
        base.children.push({
          label: "Consultar Permissões",
          route: "listar-permissao",
        });
      }
      if (p.hab_usuario) {
        base.children.push({
          label: "Lista de Usuários",
          route: "listar-usuarios",
        });

        base.children.push({
          label: "Logos do Dashboard",
          route: "logo-dashboard",
        });
      }
      if (p.hab_gerencia_unidade) {
        base.children.push({
          label: "Lista de Unidades",
          route: "listar-unidades",
        });
      }

     
      main.push(base);
    }
    this.fecharSideBarMobile();
    this.menuTree = main;
  },
});
</script>

<style>
:root {
  --sidebar-bg-color: #4fb3bf;
  --sidebar-item-hover: #3e9fab;
  --sidebar-item-active: #368c95;
}
</style>

<style scoped>

.cursorShow {
  cursor: pointer;
}

.sidebar {
  color: #fff;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-y: auto;
  bottom: 0;
  padding: 0.5rem;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
}

.collapse-icon {
  position: absolute;
  right: 0;
  padding: 0.75rem;

  color: rgba(255, 255, 255, 0.7);

  transition: 0.2s linear;
}
.collapse-icon:hover {
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>
